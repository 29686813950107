import { ThemeProvider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import theme from "../theme";

const useStyles = makeStyles(theme => ({
  blogLink: {
    outline: "none",
    textDecoration: "none",
    padding: "2px 1px 0",
    color: "#0b9444",

    "&focus": {
      borderBottom: "1px solid",
    },

    "&:hover": {
      borderBottom: "1px solid",
    },

    "&:active": {
      color: "#0bbb44",
    },
  },
}));

const BlogPage = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Layout>
          <SEO title="Missie" />

          <Typography
            data-aos="fade-right"
            variant="h5"
            style={{ marginBottom: 30, marginTop: 20 }}
          >
            Ouderverstoting
          </Typography>

          <Typography
            data-aos="fade-right"
            variant="subtitle1"
            style={{ marginBottom: 30 }}
          >
            Begin september 2022 verscheen het volgende artikel op het
            Nieuwsblad:{" "}
            <a className={classes.blogLink} href="https://www.nieuwsblad.be/cnt/dmf20220911_95719996">
              Na actie van 10 dagen om dochters te zien verlaat Maxime (37)
              torenkraan onder luid applaus
            </a>
            . Een vader van 37 jaar verschanste zich gedurende 10 dagen in een
            torenkraan om aandacht te vragen voor het feit dat hij al bijna 5
            jaar zijn dochters niet te zien krijgt. Het zou hier mogelijks
            kunnen gaan om een geval van ouderverstoting. Er is sprake van
            ouderverstoting wanneer een kind een ouder afwijst of het contact
            verbreekt, zonder geldige reden. De andere ouder werkt soms bewust
            mee aan deze afwijzing, maar vaak ook onbewust. Soms is een ouder
            immers zo boos op de ex-partner, dat hij of zij alleen maar slechte
            dingen meer kan zeggen aan het kind over hem of haar. Uiteraard
            heeft dit ook een invloed op hoe het kind naar de andere ouder
            kijkt. Enkele <strong>signalen</strong> die kunnen wijzen op
            oudervervreemding zijn: • Afwijzing van de ouder waar het kind niet
            (frequent) verblijft • Geen duidelijke of betekenisvolle argumenten
            voor deze afwijzing • Gebrek aan nuancering: de ene ouder is goed,
            de andere is slecht De laatste jaren wordt er minder expliciet over
            ouderverstoting/vervreemding gesproken, maar wel over een verstoorde
            ouder-kind-relatie en hiernaar wordt gekeken vanuit een relationeel
            perspectief met aandacht voor complexe, circulaire dynamieken. Dit
            is vaak gelinkt aan een scheiding met aanhoudend conflict, en kan
            worden gezien als een ernstige vorm (of gevolg) van een{" "}
            <strong>loyaliteitsconflict</strong>. (
            <a className={classes.blogLink} href="https://www.scheidingskoffer.be/overzicht/ouders/wanneer-is-er-sprake-van-ouderverstoting-of-oudervervreemding">
              Wanneer is er sprake van ouderverstoting of oudervervreemding?
            </a>
            - Meer uitleg rond ouderverstoting vindt u bij het Kenniscentrum
            Gezinswetenschappen). Concilium-bemiddeling zorgt ervoor dat het{" "}
            <strong>niet tot een vechtscheiding</strong> en bijhorende procedure
            voor de familierechtbank hoeft te komen.{" "}
            <strong>Ik luister actief</strong> naar de noden van beide partners
            en begeleid hen naar een overeenkomst waar zij zich beiden in kunnen
            vinden en die in het belang van de kinderen is. Op die manier houden
            partijen geen wrang gevoel over aan de scheiding en kan het contact
            van de kinderen met beide ouders op een <strong>positieve</strong>{" "}
            manier behouden blijven. Nog vragen? Aarzel niet om contact op te
            nemen!
          </Typography>

          <Typography
            data-aos-delay="300"
            data-aos="fade-left"
            variant="h5"
            style={{ marginBottom: 30 }}
          >
            Geen verplichting meer tot deelname aan bemiddeling voor
            slachtoffers van intrafamiliaal geweld
          </Typography>

          <Typography
            data-aos-delay="300"
            data-aos="fade-left"
            variant="subtitle1"
            style={{ marginBottom: 30 }}
          >
            In de nacht van 27 oktober 2022 op 28 oktober 2022 heeft de federale
            Kamer een wetsvoorstel omtrent de afschaffing van verplichte
            bemiddeling voor slachtoffers van huiselijk geweld goedgekeurd. Op
            vandaag is het immers zo dat een rechter een bemiddeling kan{" "}
            <strong>opleggen</strong>, bijvoorbeeld in het kader van een
            echtscheidingsprocedure, indien hij meent dat er een bemiddeld
            akkoord tussen beide partijen kan bereikt worden. Dit kan zelfs op
            vraag van één van beide partijen. Evenwel leerde de praktijk ons dat
            het verplicht opleggen van een bemiddeling de geweldplegers vaak de
            kans geeft om hun slachtoffers onder druk te zetten met
            onevenwichtige akkoorden tot gevolg. Daarnaast kan de confrontatie
            met de aanvaller voor een serieuze psychologische impact bij het
            slachtoffer zorgen. Daar wordt dus nu aan geremedieerd.
            Concilium-Bemiddeling zorgt er steeds voor dat de bemiddeling op een{" "}
            <strong>correcte, rustige en gemoedelijke</strong> manier
            plaatsvindt en dat <strong>beide</strong> partijen zich in het
            bemiddelde akkoord kunnen vinden. Dat is immers de basis voor een
            akkoord dat langdurig stand zal houden. Wenst u beroep te doen op
            Concilium-Bemiddeling? Aarzel niet om contact op te nemen!
          </Typography>
        </Layout>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default BlogPage;
